<!--Footer Starts-->
<footer class="footer">
  <p class="copyright clearfix text-muted m-0">
    Copyright &copy; {{currentDate | date: 'yyyy'}} <!--<a id="pixinventLink"
      href="https://themeforest.net/user/pixinvent/portfolio?ref=pixinvent">C-Digitech Solutions Ltd</a>-->
      <a href="https://c-digitechsolutions.com.ng">C-Digitech Solutions Ltd</a>
    <span class="d-none d-sm-inline-block">, All rights reserved.</span>
  </p>
</footer>
<!--Footer Ends-->
