// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  /*allmembersurl:'http://localhost:4321/Members',
  membersdetailsurl:'http://localhost:4321/Members/',
  statesandcountriesurl:'http://localhost:4321/StatesCountries',
  ranksanddivisionurl:'http://localhost:4321/RanksAndDivisions',
  updatememberurl:'http://localhost:4321/Members/UpdateMember',
  updatepassporturl:'http://localhost:4321/Members/UpdatePassport',
  updatestatusurl:'http://localhost:4321/Members/UpdateMemberStatus',
  monthlyconturl:'http://localhost:4321/Members/UpdateMonthlyContribution',
  insertmemberurl:'http://localhost:4321/Members/',
  loanurl:'http://localhost:4321/Loans/',
  loantypesurl:'http://localhost:4321/LoanType/Status/',
  memberseacrhurl:'http://localhost:4321/Members/MembersSearch/',
  insertloanurl:'http://localhost:4321/loans',
  loandetailsurl:'http://localhost:4321/Loans/',
  updateloanurl:'http://localhost:4321/Loans/',
  inserttransurl:'http://localhost:4321/Transaction/',
  memberstransactionurl:'http://localhost:4321/Transaction/transactions/',
  dashboardurl:'http://localhost:4321/Dashboard',
  authurl:'http://localhost:4321/Admin/login',
  forgotpasswordurl:'http://localhost:4321/Admin/ForgotPassword',
  adminprofileurl:'http://localhost:4321/Admin/',
  updateprofileurl:'http://localhost:4321/Admin/UpdateProfile',
  updatepasswordurl:'http://localhost:4321/Admin/UpdatePassword',
  applicationurl:'http://localhost:4321/Applications/',
  updateappstatusurl:'http://localhost:4321/Applications/',
  manageadminurl:'http://localhost:4321/Admin',
  createadminurl:'http://localhost:4321/Admin',
  updateadminurl:'http://localhost:4321/Admin/UpdateAdminProfile',
  resetpasswordfromadminurl:'http://localhost:4321/Admin/ForgotPassword',

  facilitytypeurl:'http://localhost:4321/LoanType/',
  withdrawlrequirementsurl:'http://localhost:4321/Transaction/WithdrawalRequirements',
  postwithdrawalurl:'http://localhost:4321/Transaction/PostWithdrawals',

  scheduleurl:'http://localhost:4321/Schedule/InsertSchedule',
  processscheduleurl:'http://localhost:4321/Schedule/ProcessSchedule',
  updateschedulstatuseurl:'http://localhost:4321/Schedule/UpdateMemberScheduleStatus',*/

  allmembersurl:'https://api.alyaseersociety.org.ng/Members',
  membersdetailsurl:'https://api.alyaseersociety.org.ng/Members/',
  statesandcountriesurl:'https://api.alyaseersociety.org.ng/StatesCountries',
  ranksanddivisionurl:'https://api.alyaseersociety.org.ng/RanksAndDivisions',
  updatememberurl:'https://api.alyaseersociety.org.ng/Members/UpdateMember',
  updatepassporturl:'https://api.alyaseersociety.org.ng/Members/UpdatePassport',
  updatestatusurl:'https://api.alyaseersociety.org.ng/Members/UpdateMemberStatus',
  monthlyconturl:'https://api.alyaseersociety.org.ng/Members/UpdateMonthlyContribution',
  insertmemberurl:'https://api.alyaseersociety.org.ng/Members/',
  loanurl:'https://api.alyaseersociety.org.ng/Loans/',
  loantypesurl:'https://api.alyaseersociety.org.ng/LoanType/Status/',
  memberseacrhurl:'https://api.alyaseersociety.org.ng/Members/MembersSearch/',
  insertloanurl:'https://api.alyaseersociety.org.ng/loans',
  loandetailsurl:'https://api.alyaseersociety.org.ng/Loans/',
  updateloanurl:'https://api.alyaseersociety.org.ng/Loans/',
  inserttransurl:'https://api.alyaseersociety.org.ng/Transaction/',
  memberstransactionurl:'https://api.alyaseersociety.org.ng/Transaction/transactions/',
  dashboardurl:'https://api.alyaseersociety.org.ng/Dashboard',
  authurl:'https://api.alyaseersociety.org.ng/Admin/login',
  forgotpasswordurl:'https://api.alyaseersociety.org.ng/Admin/ForgotPassword',
  adminprofileurl:'https://api.alyaseersociety.org.ng/Admin/',
  updateprofileurl:'https://api.alyaseersociety.org.ng/Admin/UpdateProfile',
  updatepasswordurl:'https://api.alyaseersociety.org.ng/Admin/UpdatePassword',
  applicationurl:'https://api.alyaseersociety.org.ng/Applications/',
  updateappstatusurl:'https://api.alyaseersociety.org.ng/Applications/',

  manageadminurl:'https://api.alyaseersociety.org.ng/Admin',
  createadminurl:'https://api.alyaseersociety.org.ng/Admin',
  updateadminurl:'https://api.alyaseersociety.org.ng/Admin/UpdateAdminProfile',
  resetpasswordfromadminurl:'https://api.alyaseersociety.org.ng/Admin/ForgotPassword',
  facilitytypeurl:'https://api.alyaseersociety.org.ng/LoanType/',
  withdrawlrequirementsurl:'https://api.alyaseersociety.org.ng/Transaction/WithdrawalRequirements',
  postwithdrawalurl:'https://api.alyaseersociety.org.ng/Transaction/PostWithdrawals',

  scheduleurl:'https://api.alyaseersociety.org.ng/Schedule/InsertSchedule',
  processscheduleurl:'https://api.alyaseersociety.org.ng/Schedule/ProcessSchedule',
  updateschedulstatuseurl:'https://api.alyaseersociety.org.ng/Schedule/UpdateMemberScheduleStatus',


};
